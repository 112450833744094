<template>
  <!--srcviewscompanylistcompanyvuehtmlStart-->

  <div id="defaultId1" class="uedbd2" style="display: flex">
    <!-- <div id="g00237" @click="setThemeColor()"  >文字{{$store.state.app}}
      </div>
         设置主题色
         
         <div id="cb4fc2"
           >设置工具配置{{$store.state.app.toolsConfig}}
         </div> -->
    <div :class="token && this.Saved == '' && !this.Compare ? '' : 'vague'" style="height: 100%;">
      <div class="left_menu" :class="$store.state.app.isMenu ? '' : 'hidemenu'">

        <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()" ref="collapseItem">
          <el-collapse-item title="Country" name="2">
            <template slot="title">
              <div class="collapsetitel">Country</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="cas_panel cas_loc">
              <input type="text" v-model="cascInptCountry" v-show="showInptCountry" class="cas_inpt"
                placeholder="Select Country" @input="inptCountrySearch" />
              <el-cascader ref="Country" v-click-outside="clickOutSide3" placeholder="Select Country" :key="cascaderKey"
                v-model="countryCheckList" @change="menuCheck('country')" :options="countryList"
                :props="{ multiple: true }" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                <template slot-scope="{ data }">
                  <el-tooltip class="casca_lab" effect="dark" :disabled="data.label.length < 10" :content="data.label"
                    placement="left">
                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                    <span class="casca_lab">
                      <span ref="sty">{{ data.label }}</span>
                    </span>
                  </el-tooltip>
                  <!-- <el-tooltip class="casca_count" effect="dark"  :content="data.count | FilterNum" placement="right"> -->
                  <span class="casca_count">{{ data.count | FilterNum }}</span>
                  <!-- </el-tooltip> -->
                </template>
              </el-cascader>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Industry" name="3">
            <template slot="title">
              <div class="collapsetitel">Industry</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="cas_panel cas_loc">
              <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt"
                placeholder="Select Industry" @input="inptIndSearch" />
              <el-cascader ref="Industry" v-click-outside="clickOutSide4" clearable v-model="industryCheckList"
                :key="cascaderKey" :options="industryList" :props="optionsProps" @change="menuCheck('Industry')"
                placeholder="Select Industry" popper-class="cas_loc_box indystry">
                <template slot-scope="{ data }">
                  <el-tooltip class="" effect="dark" :content="data.name" popper-class="el-tooltip-postion"
                    placement="right">
                    <span class="casca_lab casca_labtwo">{{ data.name }}</span>
                  </el-tooltip>
                </template>
              </el-cascader>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Company Type" name="4">
            <template slot="title">
              <div class="collapsetitel">Company Type</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="coll_child">
              <el-checkbox-group v-model="companyTypeCheckList" @change="menuCheck('companyType')">
                <el-checkbox v-for="(child, i) in companyTypeList" :key="i" :label="child.name">
                  <el-tooltip :disabled="isShowTooltip" class="line_1 coll_child_name" effect="dark"
                    :content="child.name" placement="right">
                    <div @mouseover="onMouseOver(child.name, i)" class="line_1 coll_child_name">
                      <span style="font-style: none" ref="str">{{
                        child.name
                      }}</span>
                    </div>
                  </el-tooltip>
                  <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum"
                    placement="right">
                    <span class="coll_child_num line_1">{{
                      child.count | FilterNum
                    }}</span>
                  </el-tooltip>
                  <!-- <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Company Size" name="5">
            <template slot="title">
              <div class="collapsetitel">Company Size</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="coll_child">
              <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.name">
                  <div class="line_1 coll_child_name">{{ child.name }}</div>
                  <span class="coll_child_num line_1">{{
                    child.count | FilterNum
                  }}</span>
                  <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Company Status" name="6">
            <template slot="title">
              <div class="collapsetitel">Company Status</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="coll_child">
              <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.name">
                  <div class="line_1 coll_child_name">{{ child.name }}</div>
                  <span class="coll_child_num line_1">{{
                    child.count | FilterNum
                  }}</span>
                  <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Listed Company" name="7">
            <template slot="title">
              <div class="collapsetitel">Listed Company</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="coll_child">
              <el-checkbox-group v-model="listedCompanyCheckList" @change="menuCheck('listedCompany')">
                <el-checkbox v-for="(child, i) in listedCompanyList" :key="i" :label="child.name">
                  <div class="line_1 coll_child_name">{{ child.name }}</div>
                  <span class="coll_child_num line_1">{{
                    child.count | FilterNum
                  }}</span>
                  <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Years in Business" name="8">
            <template slot="title">
              <div class="collapsetitel">Years in Business</div>
              <!-- <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i> -->
            </template>
            <div class="coll_child">
              <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.name">
                  <div class="line_1 coll_child_name">{{ child.name }}</div>
                  <span class="coll_child_num line_1">{{
                    child.count | FilterNum
                  }}</span>
                  <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-collapse-item>
        </el-collapse>

      </div>
    </div>
    <!-- v-infinite-scroll="handleScroll" ref="handleScroll"  :infinite-scroll-distance="10"  :infinite-scroll-delay="200" -->
    <div id="cc5f49" :class="$store.state.app.isMenu
      ? 'scrollbar udb507'
      : ' scrollbar udb507 udb507two'
      ">
      <div class="right_cont_box computer_right_cont_box company_box">
        <div class="table-index" v-show="!Compare">
          <div class="search_rsult_txttwo line_2">
            <span> Found over {{ page.total | FilterNum }} <span v-if="!Remove">" {{
              $store.state.app.labelCodeName == '' ? searchName : $store.state.app.labelCodeName }}" results</span> </span>
            <!-- {{ page.pageNo }}-{{ page.pageSize }} of over
            {{ page.total | FilterNum }} results for "{{ searchName }}" -->
            <el-button v-if="isOpenSlct && !Remove"
              :class="$store.state.app.collectList.length == 0 ? ' btn_export btn_exporthui' : 'btn_export'"
              :disabled="$store.state.app.collectList.length == 0" @click="CollectCheckBook">Confirm</el-button>
            <div v-if="Remove" style="display: flex;align-items: center;">
              <svg t="1728612191932" @click="backonlonad()" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="9222" width="24" height="24">
                <path
                  d="M879.476364 470.341818H244.829091L507.112727 209.454545a41.658182 41.658182 0 0 0-58.88-58.88L114.967273 482.676364a41.890909 41.890909 0 0 0 0 58.88l333.265454 333.032727a41.658182 41.658182 0 0 0 58.88 0 41.890909 41.890909 0 0 0 0-58.88L244.829091 553.658182h634.647273a41.658182 41.658182 0 1 0 0-83.316364z"
                  p-id="9223" fill="#1296db"></path>
              </svg>
              <el-button
                :class="$store.state.app.changeList.length == 0 ? ' btn_export btn_exporthui' : 'btn_exportred btn_export'"
                :disabled="$store.state.app.changeList.length == 0" @click="CollectDelate">Remove</el-button>
            </div>
          </div>

          <div class="table_box company_list table_container" id="tableBox" @scroll="handleScroll($event)"
            ref="tableBox">
            <el-table class="table_height_30 table_font_size_14 table_contentHeight_40 table_padding_0"
              @selection-change="handleSelectionChange" ref="treeTable" id="tableNode" v-loadmore="loadMorePerson"
              :key="refresh" :data="parentTable" :height="'calc(100vh - 19.428571428571427rem)'" empty-text="No Data"
              :row-class-name="getRowClass" :border="true" cell-class-name="company line_1" row-key="aaa_id"
              :expand-row-keys="expands" v-loading="loading">
              <el-table-column v-if="isOpenSlct" type="selection" width="40 " align="center">
              </el-table-column>
              <el-table-column type="expand" align="center" width="1">
                <template slot-scope="scope">
                  <div class="tree_table_box" v-if="scope.row.branchNumber == '1'">
                    <el-table :data="childTable" :border="true" cell-class-name="company line_1" :key="refresh"
                      :show-header="false" empty-text="No Data">
                      <el-table-column label="Company Name" min-width="367" align="left" show-overflow-tooltip
                        :resizable="false" class-name="company company_link ">
                        <template slot-scope="scope">
                          <div class="line_1">
                            <img :src="scope.row.flag" />
                            <div @click="setid(scope.row)" class="tbl_company line_1">
                              {{
                                (scope.row.company_name_en == '' || scope.row.company_name_en == undefined || scope.row.company_name_en == null
                              )?scope.row.company_name:scope.row.company_name_en}}
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="Location" v-if="selectedColumns.includes('province_en')" prop=""
                        align="left" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div class="line_1">
                            {{ scope.row.city_en }}
                            <span class="tbl_dot" v-show="scope.row.province_en &&
                              scope.row.province_en != ' ' &&
                              scope.row.province_en != '' &&
                              scope.row.city_en != '' &&
                              scope.row.city_en != ' ' &&
                              scope.row.city_en
                              ">,</span>
                            {{ scope.row.province_en }}
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column label="Industry" v-if="selectedColumns.includes('isic_code')" prop="isic_en"
                        align="left" show-overflow-tooltip :resizable="false">
                      </el-table-column>
                      -->
                      <!-- <el-table-column v-for="(item, index) in selectedColumns" :key="index" :label="item.screenName"
                        :prop="item.columnName" show-overflow-tooltip :resizable="false" align="left" /> -->
                      <el-table-column v-for="(item, index) in selectedColumns" :key="index" :label="item.screenName"
                        min-width="200" :prop="item.columnName" show-overflow-tooltip :resizable="true" align="left" />
                      <template v-if="retchildren" slot="empty">
                        <div style="
                          padding: 1.428em;
                          text-align: left;
                          line-height: 2.857em;
                        ">
                          if the search results are not as you expected, please
                          revise your search keywords and use advanced selection
                          criteria in the left panel to enhance your search
                          result. Meanwhile, you can contact our service team to
                          report errors or for assistance.
                        </div>
                      </template>

                      <template v-else slot="empty">
                        <div style="text-align: center; width: 100%">No Data</div>
                      </template>
                    </el-table>

                    <div class="page_i_box">
                      <div class="search_rsult_txt line_2">
                        {{ pageChild.pageNo }}-{{ pageChild.pageSize }} of over
                        {{ pageChild.total | FilterNum }} results for "{{
                          searchName
                        }}"
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Company Name" min-width="385" prop="company_name_en" align="left"
                class-name="company company_link " show-overflow-tooltip :resizable="true">
                <template slot-scope="scope">
                  <div class="line_1" style="min-width: 365px">
                    <div class="btn_expand" :class="{ expand_icon: scope.row.isExpand }"
                      @click="onClickExpansion(scope.row)"></div>

                    <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                    <div @click="setid(scope.row)" class="tbl_company line_1">
                      {{
                        (scope.row.company_name_en == '' || scope.row.company_name_en == undefined || scope.row.company_name_en == null
                        ) ? scope.row.company_name:scope.row.company_name_en}}
                    </div>
                  </div>
                </template>
              </el-table-column>



              <el-table-column v-for="(item, index) in selectedColumns" :key="index" :label="item.screenName"
                min-width="200" :prop="item.columnName" show-overflow-tooltip :resizable="true" align="left" />

            </el-table>

          </div>
        </div>
        <div class="CompanyCompany" v-show="Compare">
          <compareCompanys :compareList="compareList" @CompareShowfalse="CompareShow($event)"></compareCompanys>
        </div>
      </div>

    </div>
    <Suo :suoClose="Update" @closeCloseDialog="clossuoDialog($event)" @closeCloseDialog1="clossuoDialog1($event)"></Suo>
    <Reporterror :showReport="ReportError" :companynameen="CompanyName" @closeReportDialog="closeDialog($event)"
      :powerStatus="powerToReportErrorStatus"></Reporterror>
  </div>

  <!--srcviewscompanylistcompanyvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import bus from '@/components/bus';
import { mixins1 } from "@/mixins/index";
import { searchCompany, selectGroup, delCollection } from "@/api/searchApi.js";
import { addItems, showItems, removeItems } from "@/api/userApi";
import Reporterror from "./reportError.vue";
import Suo from "./UpdateDialog.vue";
import compareCompanys from './compareCompanys'
import {
  getoptions,
  getCompanyDetailMenuList,
  getIsCollect,
} from "@/api/companyApi";
// interfaceCode
// import gui_table from "../../../components/gui_table/gui_table.vue";
//importEnd

export default {
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {

          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= 0.5) {
            binding.value(); // 执行绑定的函数
          }
        });
      },

    },
  },
  mixins: [mixins1],
  components: {
    Suo,
    Reporterror,
    compareCompanys
    // gui_table
  },
  name: "",
  props: {},
  watch: {
    token: {
      handler(newVal, oldVal) {
        if (newVal) {

        } else {
          this.openMenu = []
          this.setToolsfalse()
        }
      },
      immediate: true,
    },
    //  Label
    '$store.state.app.labelCode': {
      handler(newVal, oldVal) {
        // 在这里处理columns属性的变化
        // if(newVal!=''){
        this.labelCode = newVal
        this.page.pageNo = 1
        this.parentTable = []
        this.searchCompanyByName()

        // 这里可以进行一些逻辑处理或者触发其他操作
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    // columns
    '$store.state.app.ColumnsArr': {
      handler(newVal, oldVal) {
        // 在这里处理columns属性的变化
        if (newVal.length > 0) {
          this.selectedColumns = newVal;

        } else {
          this.selectedColumns = [];
        }
        // 这里可以进行一些逻辑处理或者触发其他操作
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Clearallflase: {
      handler(newVal, oldVal) {
        if (oldVal != undefined) {
          this.clearAllTags();
        }
      },
      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    isOpenSlct: {
      handler(newVal, oldVal) {
        this.isOpenSlct = newVal;
        // 在这里处理columns属性的变化
        if (newVal == false) {
          this.handleSelectionChange([])
        }
        this.refresh++

        // 这里可以进行一些逻辑处理或者触发其他操作
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Sort: {
      handler(newVal, oldVal) {
        this.Sort = newVal;
        if (newVal.length > 0) {

          this.page.pageNo = 1
          this.parentTable = []
          this.searchCompanyByName()

        }
        // 在这里处理Sort属性的变化
      },
      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Saved: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.parentTable = [];
          this.pageNo = 1
          this.Saved = newVal;
          this.getCollection()

        }

        // 在这里处理Sort属性的变化
      },
      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Update: {
      handler(newVal, oldVal) {
        this.Update = newVal;
        // 在这里处理columns属性的变化
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Compare: {
      handler(newVal, oldVal) {
        this.Compare = newVal;
        if (this.Compare) {
          this.setToolsfalse()
        } else {
          this.handleSelectionChange([])
          this.refresh++
        }
        // 在这里处理columns属性的变化
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    Export: {
      handler(newVal, oldVal) {
        this.Export = newVal;
        // 在这里处理columns属性的变化
      },
      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },
    ReportError: {
      handler(newVal, oldVal) {
        this.ReportError = newVal;
        this.powerToReportErrorStatus = true;
        // 在这里处理columns属性的变化
      },

      deep: true, // 深度监听，可以监听对象内部属性的变化
      immediate: true, // 立即执行一次handler函数，即使columns没有变化
    },

    countryList(val) {
      this.cascaderKey++;
    },
    industryList(val) {
      this.cascaderKey++;
    },
  },
  updated() {
    this.$nextTick(function () {
      this.formatCC();
      //   this.showInptCountry=true
    });

    window.addEventListener("setItem", () => {
      this.token = localStorage.getItem("token");
    });
  },
  beforeDestroy() {
    this.$off('closeReportDialog');
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    this.getPower();
   
  },
  mounted() {
    this.setThemeColor();
    this.setTools();
  },
  computed: {
    filteredColumns() {
      return this.columns.filter((column) => column.visible);
    },
  },

  data() {
    return {
      compareList: [],
      Remove: false,
      isFadedIn: true,
      loading: true,
      isOpenSlct: false,
      Clearallflase: false,
      Compare: false,
      Export: false,
      Download: false,
      Update: false,
      Saved: '',
      Sort: [],
      labelCode: '',
      ReportError: false,
      CompanyName: "",
      powerToReportErrorStatus: false,
      retchildren: false,
      // columns
      columns: [
        "company_name",
        "province_en",
        "city_en",
        "district_en",
        "isic_code",
        'incorporation_year',
        "company_type_code",
        "scale_code",
        "status",
      ],
      pageChild: {
        pageNo: 1,
        pageSize: 50,
        total: 0,
      },
      page: {
        pageNo: 1,
        pageSize: 100,
        total: 0,
      },
      pageNo: 1,
      refresh: 0,
      pageSize: 100,
      powerResult: { list: [] },
      token: localStorage.getItem("token"),
      optionsProps: {
        label: "name", //名称展示
        value: "value", //值展示
        expandTrigger: "click", //次级菜单的展开方式
      },
      parentTable: [],
      expands: [],
      cascInptCountry: "",
      showInptCountry: true,
      cascInptIndustry: "",
      showInptIndustry: true,
      childTable: [],

      showColumnSelector: false,
      selectedColumns: [

      ],

      checkedCompanyList: [],
      companyList: [],
      companyTblList: [],
      branchflag: "",
      openMenu: ['2', '3', '4', '5', '6', '7', '8'],
      // openMenu: [],
      // geographicalLocation:[],
      countryPreList: [],
      countryList: [],
      countryCheckList: [],
      countryTag: [],
      countryCheck: "",

      industryPreList: [],
      industryList: [],
      industryCheckList: [],
      industryTag: [],
      industryCheck: "",

      companyTypeList: [],
      companyTypeList1: [],
      companyTypeCheckList: [],
      companyTypeTag: [],
      companyTypeCheck: "",

      companySizeList: [],
      companySizeCheckList: [],
      companySizeTag: [],
      companySizeCheck: "",

      companyStatusList: [],
      companyStatusCheckList: [],
      companyStatusTag: [],
      companyStatusCheck: "",

      listedCompanyList: [],
      listedCompanyCheckList: [],
      listedCompanyTag: [],
      listedCompanyCheck: "",

      timePeriodList: [],
      timePeriodCheckList: [],
      timeTag: [],
      timePeriodCheck: "",
      province: [],
      city: [],
      keywords: "",
      cascaderKey: 0,
      loadig: false,
      searchName: "",
      isShowTooltip: false,
      checkList: [],
      labelCodeName: ''
      // srcviewscompanylistcompanyvueDataEnd
    };
  },

  methods: {
    clickOutSide3() {
      if (this.$refs.Country) {
        this.$refs.Country.dropDownVisible = false;

      }
    },
    clickOutSide4() {
      if (this.$refs.Industry) {
        this.$refs.Industry.dropDownVisible = false;

      }
    },
    loadMorePerson() {
      // 加载更多数据的逻辑
      let el = document.getElementById('tableNode')
      if (this.Saved) {
        this.pageNo = this.pageNo + 1
        if (this.page.total > this.parentTable.length) {
          this.getCollection()
        }

      } else {
        this.page.pageNo = this.page.pageNo + 1;
        if (this.page.total > this.parentTable.length) {
          this.searchCompanyByName()
        }

      }

    },
    backonlonad() {
      this.$router.go(0);
    },
    // 触底刷新
    handleScroll(e) {
      const scrollTop = e.target.scrollTop; //滑入屏幕滚动条滚动时，距离顶部的距离
      const windowHeitht = e.target.clientHeight; //能看到的页面的高度
      const scrollHeight = e.target.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      const total = scrollTop + windowHeitht + 1;

      if (total >= scrollHeight) {


        if (this.Saved) {
          this.pageNo = this.pageNo + 1
          this.getCollection()
        } else {
          this.page.pageNo = this.page.pageNo + 1;
          if (this.page.total > this.parentTable.length) {
            // this.searchCompanyByName(total)
          }

        }


        //加载操作
      }

    },
    //搜索公司
    async searchCompanyByName(total) {
      // this.parentTable=[]

      this.searchName = this.$route.query.searchname
        ? this.$route.query.searchname
        : "";
      this.searchCode = this.$route.query.companyCountry
        ? this.$route.query.companyCountry
        : "";
      this.keywords = this.$route.query.keword;
      // const companyCountry = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
      // this.industryCheck = this.industryCheck == undefined ? '' : this.industryCheck;

      let params = {
        companyLabel: this.labelCode,
        company_country: this.countryCheck
          ? this.countryCheck
          : this.$route.query.companyCountry,
        company_name: this.labelCode == '' ? this.searchName : '',
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
        // company_country: this.searchCode,
        province_en: this.province,
        city_en: this.city,
        isic_code: this.industryCheck,
        company_class_code: this.companyTypeCheck,
        searchType: this.keywords,
        scale_code: this.companySizeCheck,
        reg_status: this.companyStatusCheck,
        listing_status_code: this.listedCompanyCheck,
        years: this.timePeriodCheck,
        sortType: this.Sort[0],
        sortColumn: this.Sort[1],
      };
      //    'company_name=' + this.searchName + '&company_country=' + this.searchCode + '&page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&isic_code=' + this.industryCheck + '&company_class_code=' + this.companyTypeCheck + '&searchType=' + this.keywords + '&scale_code=' + this.companySizeCheck + '&reg_status=' + this.companyStatusCheck + '&listing_status_code=' + this.listedCompanyCheck + '&years=' + this.timePeriodCheck
      let res = await searchCompany(params);

      let parentTable = [];
      if (res.code) {
        this.loading = false;
        this.page.total = res.data.total;
        let list = (parentTable = res.data.rows);

        //查找本地图片
        list = list.map((item, index) => {
          let countryImg = this.getCountryImgUrl(item.company_country);
          if (countryImg) {
            let flag = require("../../../../static" + countryImg);
            parentTable[index].flag = flag;
            this.refresh++;
          }
        });
        this.parentTable.push(...parentTable)
        if (total) {
          var tableBox = document.getElementById('tableBox'); // 获取需要滚动的节点
          this.$nextTick(() => {
            tableBox.scrollTop = total - 10;
          })
        }
  

      } else {
        this.loading = false;
        // this.addPopIndex2=true
        if (res.code == "1002") {
          this.$message({
            message: res.msg,
            center: true,
            type: "error",
          });
        } else {
          this.parentTable = [];

        }
      }
    },
    // 获取收藏
    getCollection() {
      showItems({
        collectionId: this.Saved,
        page: this.pageNo,
        size: 100,
      }).then((res) => {

        if (res.code == 200) {
          this.Remove = true
          this.$store.dispatch("app/setchangeList", []);
          this.$store.dispatch("app/setTools", {
            name: "collect",
            key: "isActive",
            value: false,
          });
          this.$store.dispatch("app/setTools", {
            name: "sort",
            key: "isActive",
            value: false,
          });
          this.$store.dispatch("app/setTools", {
            name: "filter",
            key: "isActive",
            value: false,
          });
          this.loading = false;
          this.page.total = res.data.total;
          let parentTable = [];
          let list = (parentTable = res.data.rows);

          //查找本地图片
          list = list.map((item, index) => {
            let countryImg = this.getCountryImgUrl(item.company_country);
            if (countryImg) {
              let flag = require("../../../../static" + countryImg);
              parentTable[index].flag = flag;
              this.refresh++;
            }
          });
          this.parentTable.push(...parentTable)
        } else {
          this.loading = false;
          // this.addPopIndex2=true
          this.parentTable = [];
          if (res.ret == "1002") {
            this.ret = true;
          } else {
            this.ret = false;
          }
          // this.page.total = res.data.total;
        }
      });
    },
    // 收藏
    CollectCheckBook() {
      this.checkList = this.$store.state.app.collectList
      let collect = [];
      this.$store.state.app.changeList.map((item) => {
        collect.push(item.aaa_id);
      });

      // this.$store.state.app.changeLis;
      this.checkList.forEach((item) => {
        addItems({
          collectionId: item,
          items: collect,
        }).then((Res) => {
          if (Res.code == 200) {
            this.$message({
              message: Res.msg,
              center: true,
              type: "success",
            });
          } else {
            this.$message({
              message: Res.msg,
              center: true,
              type: "error",
            });
          }
        });
      });
    },
    // 删除收藏
    CollectDelate() {

      let ids = []
      this.$store.state.app.changeList.map((item) => {
        ids.push(item.collectId);
      });
      removeItems({ ids }).then(res => {
        if (res.code == 200) {
          this.pageNo = 1
          this.parentTable = []
          this.getCollection()
          this.$message({
            message: res.msg,
            center: true,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            center: true,
            type: "error",
          });
        }
      })
    },
    // 公司对比返回
    CompareShow(val) {
      this.Compare = false
      this.setTools()

    },

    //关闭report弹窗
    closeDialog(value) {
      this.ReportError = value;
    },
    // 关闭锁谈框
    clossuoDialog(value) {
      this.Update = value;
    },

    clossuoDialog1(value) {
      // this.suopower = value;
    },
    // 清楚选中

    // 点击跳转
    setid(row) {
      if (this.token) {
        sessionStorage.setItem("company", JSON.stringify(row));
        let newscountry = JSON.parse(localStorage.getItem("countryList"))[0].countryList
          ;
        let SACode1 = "";
        newscountry.map((item) => {
          if (item.countryCode == row.company_country) {
            SACode1 = item.dowjonesCountrycode;
          }
        });
        let routeData = this.$router.resolve({
          // Tradecompany/Trade/
          // /company/detals
          path:
            "/index/company/detals/" +
            row.aaa_id +
            "/background/snapshot?id3a=" +
            // "/tradeMap/map?id3a=" +
            row.aaa_id +
            "&companyCountry=" +
            row.company_country +
            "&SACode1=" +
            SACode1 +
            "&companyName=" +
            row.company_name_en,
        });

        window.open(routeData.href, "_blank");
      }

    },
    // insertMetho
    getPower() {
      this.getConditions();
      // this.searchCompanyByName();
    },
    //
    setToolsfalse() {
      this.$store.dispatch("app/setTools", {

        name: "select",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {

        name: "edit",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "sort",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "collect",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "saved",
        key: "isActive",
        value: false,
      });

      this.$store.dispatch("app/setTools", {
        name: "compare",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "export",
        key: "isActive",
        value: false,
      });

      this.$store.dispatch("app/setTools", {
        name: "update",
        key: "isActive",
        value: false,
      });

      this.$store.dispatch("app/setTools", {
        name: "download",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "reportError",
        key: "isActive",
        value: false,
      });
    },

    // 多选
    handleSelectionChange(val) {
      this.compareList = val
      if (val.length == 1) {
        this.CompanyName = val[0].company_name_en;
      }
      this.$store.dispatch("app/setTools", {
        name: "collect",
        key: "isActive",
        value: val.length > 0 && this.Saved == false,
      });
      this.$store.dispatch("app/setTools", {
        name: "saved",
        key: "isActive",
        value: true && this.Compare == false,
      });

      this.$store.dispatch("app/setTools", {
        name: "compare",
        key: "isActive",
        value: val.length > 0,
      });
      this.$store.dispatch("app/setTools", {
        name: "export",
        key: "isActive",
        value: val.length > 0,
      });

      this.$store.dispatch("app/setTools", {
        name: "update",
        key: "isActive",
        value: false,
      });

      this.$store.dispatch("app/setTools", {
        name: "download",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "reportError",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "share",
        key: "isActive",
        value: true,
      });
      // 添加 选中公司
      this.$store.dispatch("app/setchangeList", val);
    },


    // srcviewscompanylistcompanyvueMethodStart
    // default
    default() { }, // setThemeColor
    setThemeColor() {
      this.$store.dispatch("app/setThemColor", "#1290C9");
      this.$store.dispatch("app/setSearchType", "company");
    }, //
    // 工具栏高亮控制
    setTools() {
      this.$store.dispatch("app/setTools", {
        name: "filter",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "menu",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "sort",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "select",
        key: "isActive",
        value: true && this.token,
      });
      this.$store.dispatch("app/setTools", {
        name: "edit",
        key: "isActive",
        value: true && this.token,
      });

      this.$store.dispatch("app/setTools", {
        name: "collect",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "saved",
        key: "isActive",
        value: true,
      });
      this.$store.dispatch("app/setTools", {
        name: "compare",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "export",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "update",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "download",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "reportError",
        key: "isActive",
        value: false,
      });
      this.$store.dispatch("app/setTools", {
        name: "share",
        key: "isActive",
        value: true,
      });

      //   this.$store.dispatch("app/setTools", "filter",'isActive',false);
    },
    // openDetails
    openDetails() {
      return;

      this.$router.push({
        path: "company/detals/111",
      });
    }, // slct_rows
    slct_rows(value) {
      this.rowsSlct = value;
      this.$emit("setRowsSlct", this.rowsSlct);
    }, // selectItem
    selectItem(key, item) {
      if (key == "companyName") {
        this.$router.push({
          path: "/index/company/detals/1111/background/snapshot",
        });
      }
    }, // openContentTable
    async openContentTable(id) {
      await this.$nextTick();
    },
    checkedAllCompany(val) {
      this.checkedCompanyList = val ? this.companyList : [];
    },
    //展开当前关闭其他展开表格
    onClickExpansion(row) {
      if (this.expands.indexOf(row.aaa_id) < 0) {
        this.expands = [];
        this.expands.push(row.aaa_id);
        this.branchflag = row.aaa_id;

        if (row.branchNumber == "1") {
          this.pageChild.pageNo = 1;
          this.searchChildCompanyByName();
        }
      } else {
        this.expands = [];
      }
    },
    // 判断表格是否有子项，无子项不显示展开按钮
    getRowClass(row, rowIndex) {
      // children 是你子项的数组 key
      if (row.row.branchNumber === 0) {
        return "row-expand-cover";
      }
    },
    zhankai() {
      let hasPower = true;
      if (!hasPower) {
        this.openMenu = [];
      }
      this.$refs.Country.dropDownVisible = false;
      this.$refs.Industry.dropDownVisible = false;
      // this.formatCC();
    },

    //搜索子表
    async searchChildCompanyByName() {
      const params = {
        branchflag: this.branchflag,
        page: this.pageChild.pageNo,
        pageSize: this.pageChild.pageSize,
      };
      let res = await searchCompany(params);
      if (res && res.code) {
        this.pageChild.total = res.data.total;
        let list = (this.childTable = res.data.rows);

        list = list.map((item, index) => {
          let countryImg = this.getCountryImgUrl(item.company_country);
          if (countryImg) {
            let flag = require("../../../../static" + countryImg);
            this.childTable[index].flag = flag;
          }
        });
      } else {
        this.childTable = [];
        if (res.ret == "1002") {
          this.retchildren = true;
        } else {
          this.retchildren = false;
        }
      }
    },
    // 国家筛选新增添加删除
    formatCC() {
      let htmladd = document.createElement("bottom");
      let htmladd2 = document.createElement("bottom2");

      htmladd.innerHTML = "Confirm";
      htmladd.className = "htmladd";
      htmladd2.innerHTML = "Delete";
      htmladd2.className = "htmladd2";
      htmladd.style.cssText =
        "cursor: pointer;position: absolute;bottom: -35px;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);";
      htmladd2.style.cssText =
        "cursor: pointer;position: absolute;bottom: -35px;right: 0;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);";
      let that = this;
      var el_l = document.querySelectorAll(
        ".el-popper.el-cascader__dropdown.ticket_ccCascader"
      );
      if (el_l.length > 0 && el_l.length == 1) {
        var el = el_l[0];

        if (!el.querySelector(".htmladd")) {
          el.appendChild(htmladd);
          var bo = el.querySelectorAll(".htmladd")[0];
          bo.onclick = function () {
            //   this.$nextTick(() => {
            //     that.$refs.Country.dropDownVisible = true;
            //     })
            that.$refs.Country.dropDownVisible = false;
            that.getConditions();
            that.page.pageNo = 1
            that.parentTable = []
            that.searchCompanyByName();
            // let params = {
            //   id: that.ticketObject.id,
            //   cc: that.ticketOrder.cc
            // };
            // that.$axios
            //   .put(that.$httpServer.updateTicketOrder, params)
            //   .then(res => {
            //     if (res && res.data.code == "0") {
            //       that.$message1({
            //         message: "保存成功",
            //         type: "success",
            //         duration: 500
            //       });
            //       that.handleNodeClick(that.types, that.ticketObject.id);
            //       that.getTicketsNum();
            //     }
            //   });
          };
        }

        var el1 = el_l[0];
        if (!el1.querySelector(".htmladd2")) {
          el1.appendChild(htmladd2);
          var bo1 = el1.querySelectorAll(".htmladd2")[0];
          bo1.onclick = function () {
            that.countryCheckList = [];
            that.countryTag = [];
            that.province = "";
            that.arrProvTag = [];
            that.countryCheck = "";
            that.$refs.Country.dropDownVisible = false;
            that.showInptCountry = true;
            that.parentTable = []
            that.page.pageNo = 1
            that.getConditions();
            that.searchCompanyByName();
          };
        }
      }
    },

    // Company type 超出省略 显示
    onMouseOver(str, i) {
      const tag = this.$refs.str[i];
      const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    //菜单checkbox选择
    menuCheck(val) {
      if (val === "country") {
        const checkedNodes = this.$refs.Country.getCheckedNodes();
        const allNodes = this.$refs.Country.getCheckedNodes(true);
        const isAllSelected = checkedNodes.length === allNodes.length;
        let arrCountry = [];
        let arrProv = [];
        let arrCountryTag = [];
        let arrCountrycheck = [];
        this.countryCheckList.forEach((item) => {
          if (isAllSelected) {
            if (item.length == 1) {
              if (!arrCountry.includes(item[0])) {
                this.countryList.forEach((ite) => {
                  if (ite.value == item[0]) {
                    arrCountryTag.push(ite.label); //展示
                  }
                });

                arrCountry.push(item[0]); //传值
              }
            } else {
              arrProv.push(item[1]);
            }
          } else {
            if (!arrCountry.includes(item[0])) {
              this.countryList.forEach((ite) => {
                if (ite.value == item[0]) {
                  arrCountryTag.push(ite.label); //展示
                }
              });

              arrCountry.push(item[0]); //传值
            }
          }

          if (!arrCountrycheck.includes(item[0])) {
            arrCountrycheck.push(item[0]); //传值
          }
        });

        this.countryTag = arrCountryTag;
        this.arrProvTag = arrProv;

        this.countryCheck = arrCountrycheck.join("@@");
        this.province = arrProv.join("@@");

        //显示隐藏国家级联选择器的输入框
        if (this.countryTag.length > 0 || this.arrProvTag.length > 0) {
          this.showInptCountry = false;
          this.cascInptCountry = "";
        } else {
          this.showInptCountry = true;
        }
      } else {
        if (val === "Industry") {
          let arr = [];
          this.industryCheckList.forEach((item) => {
            this.industryList.forEach((it) => {
              if (it.value == item) {
                arr.push(it);
              }
            });
          });
          this.industryTag = arr;
          this.industryCheck = this.industryCheckList[1];
          //显示隐藏行业级联选择器的输入框
          if (this.industryCheckList.length > 0) {
            this.showInptIndustry = false;
            this.cascInptIndustry = "";
          } else {
            this.showInptIndustry = true;
          }
        }
        // if (val === 'companyType') {
        let arr = [];

        this.companyTypeCheckList.forEach((item) => {
          this.companyTypeList.forEach((it) => {
            if (it.name == item) {
              arr.push(it);
            }
          });
        });
        this.companyTypeTag = arr;
        let arrlist = [];
        arr.map((item) => {
          arrlist.push(item.value);
        });

        this.companyTypeCheck = arrlist.join("@@");
        // }
        // if (val === 'companySize') {
        let arr1 = [];
        this.companySizeCheckList.forEach((item) => {
          this.companySizeList.forEach((it) => {
            if (it.name == item) {
              arr1.push(it);
            }
          });
        });
        let arrlist1 = [];
        arr1.map((item) => {
          arrlist1.push(item.value);
        });
        this.companySizeTag = arr1;
        this.companySizeCheck = arrlist1.join("@@");
        // this.companySizeCheck = this.companySizeCheckList.join('@@');
        // }
        // if (val === 'listedCompany') {
        let arr2 = [];
        this.listedCompanyCheckList.forEach((item) => {
          this.listedCompanyList.forEach((it) => {
            if (it.name == item) {
              arr2.push(it);
            }
          });
        });
        let arrlist2 = [];
        arr2.map((item) => {
          arrlist2.push(item.value);
        });
        this.listedCompanyTag = arr2;
        this.listedCompanyCheck = arrlist2.join("@@");
        // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
        // }
        // if (val === 'companyStatus') {
        let arr3 = [];
        this.companyStatusCheckList.forEach((item) => {
          this.companyStatusList.forEach((it) => {
            if (it.name == item) {
              arr3.push(it);
            }
          });
        });
        let arrlist3 = [];
        arr3.map((item) => {
          arrlist3.push(item.value);
        });
        this.companyStatusTag = arr3;
        this.companyStatusCheck = arrlist3.join("@@");
        // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
        // }
        // if (val === 'timePeriod') {
        let arr4 = [];
        this.timePeriodCheckList.forEach((item) => {
          this.timePeriodList.forEach((it) => {
            if (it.name == item) {
              arr4.push(it);
            }
          });
        });
        let arrlist4 = [];
        arr4.map((item) => {
          arrlist4.push(item.value);
        });
        this.timeTag = arr4;
        this.timePeriodCheck = arrlist4.join("@@");
        // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
        // }
        this.parentTable = []
        this.page.pageNo = 1
        // this.countryCheck = ''
        this.getConditions();
        this.searchCompanyByName(0);
      }
    },
    //获取左边菜单
    async getConditions() {
      this.loadig = true;

      const searchName = this.$route.query.searchname
        ? this.$route.query.searchname
        : "";
      const companyCountry = this.countryCheck
        ? this.countryCheck
        : this.$route.query.companyCountry;
      this.keywords = this.$route.query.keword;
      const params = {
        companyLabel: this.labelCode,
        company_country: companyCountry,
        company_name: this.labelCode == '' ? this.searchName : '',
        province_en: this.province,
        city_en: this.city,
        industry_en: this.industryCheck,
        company_class_code: this.companyTypeCheck,
        // searchType: this.keywords ,
        searchType: this.keywords,
        scale_code: this.companySizeCheck,
        reg_status: this.companyStatusCheck,
        listing_status_code: this.listedCompanyCheck,
        years: this.timePeriodCheck,
      };
      await getoptions(params).then((res) => {
        if (res && res.code) {
          this.loadig = false;
          this.countryList = res.data.geographicalLocation;
          this.industryList = res.data.industry;
          this.industryPreList = res.data.industry;

          this.companyTypeList = res.data.companyTypeList;

          // this.companyTypeList=
          this.companySizeList = res.data.companySizeList;
          this.companyStatusList = res.data.regStatus;
          this.listedCompanyList = res.data.listingStatusList;
          this.timePeriodList = res.data.yearsOfEstablishment;

          let list = this.countryList;
          if (list.length > 0) {
            list.map((item, index) => {
              item.label = item.companyCountry.name;
              item.value = item.companyCountry.value;
              item.count = item.companyCountry.count;
              if (item.companyProvince) {
                item.children = item.companyProvince;
                item.children.map((it) => {
                  it.label = it.name;
                });
              }
            });
          }
          this.countryPreList = list;
        } else {
          this.loadig = false;
        }
      });
    },
    //国家级联搜索不区分大小写
    inptCountrySearch() {
      this.$nextTick(() => {
        this.$refs.Country.dropDownVisible = true;
      });
      let queryString = this.cascInptCountry;
      var countryList = this.countryPreList;
      // this.$refs.Country.dropDownVisible=true
      var results = queryString
        ? countryList.filter(this.createFilter(queryString))
        : countryList;
      this.countryList = results;
      // this.$refs.Country.dropDownVisible=true
    },
    // 过滤数据
    createFilter(queryString) {
      return (countryList) => {
        return (
          countryList.label.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //行业级联搜索不区分大小写
    inptIndSearch() {
      this.$nextTick(() => {
        this.$refs.Industry.dropDownVisible = true;
      });
      let queryString = this.cascInptIndustry;
      var industryList = this.industryPreList;

      var results = queryString
        ? industryList.filter(this.createFilterInd(queryString))
        : industryList;
      this.industryList = results;
    },
    createFilterInd(queryString) {
      return (industryList) => {
        return (
          industryList.name.toLowerCase().indexOf(queryString.toLowerCase()) >
          -1
        );
      };
    },
    //清除所有标签
    clearAllTags() {
      this.countryCheckList = [];
      this.industryCheckList = [];
      this.companyTypeCheckList = [];
      this.companySizeCheckList = [];
      this.companyStatusCheckList = [];
      this.listedCompanyCheckList = [];
      this.timePeriodCheckList = [];
      this.page.pageNo = 1
      this.industryCheck = "";
      this.companyTypeCheck = "";
      this.companySizeCheck = "";
      this.companyStatusCheck = "";
      this.listedCompanyCheck = "";
      this.timePeriodCheck = "";
      this.showInptCountry = true;
      this.province = ''
      this.company_country = ''
      this.countryCheck = ''
      this.parentTable = []
      this.getConditions();
      this.searchCompanyByName();
    },
    // srcviewscompanylistcompanyvueMethodEnd
  },
};
</script>
<style scoped>
.uedbd2 {
  height: 100%;
}

.udb507 {
  width: calc(100% - 25em);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 0.7142857142857143em;
  flex-direction: column;
  padding-left: 0em;
  margin-left: 0.7142857142857143em;
}

.udb507two {
  width: 100% !important;
}

.u1QPawD {
  display: none;
}

.ufe717 {
  width: 12.214285714285714em;
  font-size: 1em;
  background-color: rgb(255, 255, 255);
}

.ukQ5lVZ {
  margin-left: 0.5em;
}

.u82ef5 {
  display: flex;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-left: 0.5em;
  padding-bottom: 0.5em;
}

.u0a5ae {
  flex: auto;
  margin-left: 0.5em;
}

.u4bf20 {
  position: relative;
}

.u04a90 {
  display: flex;
  align-items: flex-start;
  height: 0;
  box-sizing: inherit;
  flex: auto;
  overflow-y: auto;
  margin-top: 0.5em;
}

.u1ec6b2 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: rgb(240, 240, 240);
}

.uff2cd {
  margin-left: 3.5714285714285716em;
}

.u5t26i8 .active {
  display: block;
}

.u5t26i8 path {
  display: none;
}

.u82ef5:hover .u0a5ae {
  color: rgb(33, 104, 235);
}

.u82ef5:hover .u5a792 {
  display: block;
}

.table_box /deep/ .el-table thead .company_link>.cell {
  padding-left: 2.857142857142857em !important;
}

.tree_table_box /deep/ .el-table thead .company_link>.cell {
  padding-left: 1.4285714285714286em !important;
}

.table_box /deep/ .el-table th.el-table__cell {

  height: 3.5714285714285716em;
}

.table_box /deep/ .el-table td.el-table__cell {

  height: 4.285714285714286em;
}

.left_menu /deep/ .el-loading-mask {
  background-color: #f5f5f5 !important;
}

.left_menu {


  max-height: 95%;
  overflow-y: auto;
}

.hidemenu {
  display: block;
  width: 0em;
  padding-left: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  padding-right: 0em;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

.collapsetitel {
  margin-left: 1.7857142857142858rem;
}

/* /deep/ .el-icon-arrow-right:before {
  content: "";
    width: 1.1428571428571428em;
    height: 1.2857142857142858em;
    line-height: 1.2857142857142858em;
    text-align: center;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAAXNSR0IArs4c6QAAARNJREFUKFN9zzFLA0EQBeA3e2vUiKCVomBvVmJ1CKJomdLmcgiKlY1/wMI2WNjb2UZyB1rZiASDYKGChSSXzkIsLRSLcMlmRlZQLiBONzPvGxjyCkFdhK65HVfwT+lC+VAgK+TNBw0B1ojUVj+Jqn8ZZcI9MB8TcEWji8Fs2sUDQJMeqfVeUrvLIm2CEjMuALzkR7BEbjlkQr/PcgOS92Gd8ztP1Vc3zxXDBduTW5CwVmq524za38CVZ8qbwnIK0ON0fmL1I/0c77C9BzCj4JVsUqu73C9wjTZBhRkHRHQGYE5EfFJqt9+KTn4ODwARIW3CcxHZcAGl6Mi24v3sTwPALaaK22NvNr0EybNtxjtEJFnwBdoEZC/v0bqQAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: 0.7142857142857143em 0.42857142857142855em;
    background-position: center;
    transform: rotate(-90deg);
    float: left;
    cursor: pointer;
} */
/* /deep/ .el-collapse-item__arrow.is-active {
  left: 0.7142857142857143em !important;
} */
.table-index {
  /* display: none; */
}


.table_box /deep/ .el-table thead .company_link>.cell {
  padding-left: 2.142857142857143em !important;
}

.table_box /deep/.el-table-column--selection .cell {
  padding-right: 0em !important;
}

.table_box /deep/.el-table .cell {
  padding-left: 0em !important;
}

.tree_table_box /deep/.el-table .cell {
  padding-left: 0.7142857142857143em !important;
}

/deep/ .el-collapse-item__wrap {
  border-bottom: 0.07142857142857142em solid #cccccc;
}

/* .el-tooltip-postion{
  top: -5px;
} */
@media screen and (max-width: 1441px) {
  .collapsetitel {
    font-size: 1.14285714rem;
  }
}
</style>